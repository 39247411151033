const propertyData = {
    "url": "http://15232NWMariannaSt.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "15232 NW Marianna St", "city": "Portland", "state":"Oregon", "zipcode": "97229"},
    "display_address": "15232 NW Marianna St",
    "property": {
      "listing_price": "$509,888",
      "bedrooms": "4",
      "baths": "3 Full 2 Partial",
      "sq_feet": "2466",
      "lot_size": "1742.4",
      "rmls": "20559570",
      "built_in": "2018",
      "blurb": "Like new beautifully upgraded townhouse in Bethany! Light & bright open design. Engineered hardwood floors on main. Spacious living room w/gas fireplace. Large kitchen w/modern white cabinetry, quartz counters, island & stainless appliances. Upgraded carpet & pad upstairs. Master suite w/walk-in closets w/custom closet system by California Closets. Master bath large shower & soaking tub. Garage w/custom storage room. Overlooks THPRD greenspace. Community parks, playgrounds & athletic courts.",
      "ammenities": [

      ]
    },
    "image_count": 30,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.027729033213!2d-122.83600968443899!3d45.569880979102294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495062304e35bd5%3A0xd7efb024928aa95e!2s15232%20NW%20Marianna%20St%2C%20Portland%2C%20OR%2097229!5e0!3m2!1sen!2sus!4v1590721949551!5m2!1sen!2sus",
        "youtube":"nDLTVqkGBao"
    }
  }

export default propertyData;

